import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { FooterRecCard } from "../components/resources"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { v4 as uuidv4 } from "uuid"
import Dar from "@mui/icons-material/DoubleArrow"

const breadData = [
  {
    id: 1,
    ink: "/",
    label: "HOME",
  },
  {
    id: 3,
    ink: "/account-takeover/",
    label: "ACCOUNT TAKEOVER",
  },
]

const resourcesList = [
  {
    title: "Vigilant365™ ",
    subtitle: "Enhanced Microsoft 365 Detection",
    LinkUr: "https://sp1.sdcdn.app/pdf/V365-Solution-Brief.pdf",
    cat: "SOLUTION BRIEF",
  },
  {
    title:
      "Mid-size technology company prevents unauthorized access to Microsoft 365 account with Vigilant.",
    subtitle: "",
    LinkUr: "https://sp1.sdcdn.app/pdf/V365-Success-Story.pdf",
    cat: "SUCCESS STORY",
  },
  {
    title: "SolarWinds Orion and Office 365 Attack",
    subtitle: "",
    LinkUr: "/blog-pages/solarwinds",
    cat: "BLOG",
  },
]

const RiskReduction = () => (
  <Layout>
    <Seo title="ACCCOUNT TAKEOVER" />
    <div className="breadcrumbs">
      <nav className="container-fluid p-sides-med">
        <ul>
          {breadData.map(breadcrumb => (
            <li key={breadcrumb.id}>
              <Link to={breadcrumb.ink}>{breadcrumb.label}</Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>

    <div className="grid--bg--for-it-ops-at">
      <section className="section-title-top--platform ">
        <div className="container">
          <div className="row">
            <div className="section-col-1">
              <h1>ACCOUNT TAKEOVER</h1>
              <h4>BUSINESS EMAIL COMPROMISE </h4>
              <p className="content">
                The number one way that attackers gain access to organizations
                is through techniques like spear-phishing or social engineering
                that leads to Business Email Compromise.
              </p>
              <Link to="/contact/" className="nav-section--cta-btn">
                <div className="d-flex align-items-center">
                  <span
                    style={{ marginLeft: "25px" }}
                    className="nav-section--cta-title pr-5"
                  >
                    stop email compromise now
                  </span>
                  <Dar className="chevy" sx={{ fontSize: 15, marginLeft: 2 }} />
                </div>
              </Link>
            </div>
            <div className="section-col-2"></div>
          </div>
        </div>
      </section>
    </div>

    <section className="section-overview">
      <div className="container">
        <br />
        <div className="row pb-5">
          <div className="col-sm-12">
            <div className="section-col-rr">
              <h4>You Need a Partner to See the Clues </h4>
              <hr />
            </div>

            <div className="row">
              <div className="col-6">
                <p>
                  What we are talking about is basic, fundamental, every day,
                  necessary-for-business electronic communication. In other
                  words: Email. In 2019, Adobe reported that white-collar
                  employees spend 352 minutes a day on email and every
                  communication is a potential compromise.
                </p>
                <p>
                  All of that activity requires sophisticated technology with a
                  breadth and depth of tools, as well as a 24/7/365 security
                  presence. But even that is not enough. Account takeovers are
                  often a slow, patient, and methodical process. For full
                  protection, you need a partner who can see the clues of
                  potential takeover in their infant stages.
                </p>
                <p>
                  The tactics, techniques and procedures of threat actors are
                  tried and true and it is a volume business. They gain access
                  to an email account through phishing or social engineering
                  (often utilizing a malicious attachment or URL).
                </p>
              </div>
              <div className="col-6">
                <p>
                  Once full access is obtained, the threat actor can see the
                  account and change the account’s rules (often put in place for
                  security): turn off spam filters, disable or change
                  anti-forwarding parameters or turn off GeoIP blockers. And
                  once those rules are changed, your attacker can request and
                  move unencumbered: financial documents, invoices, HR documents
                  or sensitive IP information.
                </p>
                <p>
                  Vigilant365™ is a “built from the ground up” technology and
                  service like no other on the market. It is a real-time, across
                  the board email supervisory service that cuts through the
                  extraneous information provided by traditional Microsoft 365
                  monitoring solutions by intelligently alerting you to
                  potential account takeovers. The V365 early warning technology
                  also witnesses potential takeovers in their infancy, so it can
                  predict next steps and guide remediation before the damage is
                  done. It also goes deeper into the entire account takeover
                  scenario, mapping the attacker, marking their strategy and
                  their identity with an eye toward future attack prevention.
                  Vigilant365™ = Watching, Detecting, and Alerting.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="section-box-cta">
      <div className="container">
        <div className="row">
          <div className="section-col-1">
            <div className="section-col-1--content">
              <h2>
                Learn More about Vigilant365™ ENHANCED <br /> MICROSOFT 365
                DETECTION
              </h2>
              <hr />
              <a
                href="https://sp1.sdcdn.app/pdf/V365-Success-Story.pdf"
                className="nav-section--cta-btn"
                target="__blank"
              >
                <div className="d-flex align-items-center">
                  <span
                    style={{ marginLeft: "25px" }}
                    className="nav-section--cta-title pr-5"
                  >
                    learn more
                  </span>
                  <Dar className="chevy" sx={{ fontSize: 15, marginLeft: 2 }} />
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="section-platform ">
      <div className="container">
        <div className="row">
          <div className="col-sm-7">
            <div className="side-binder-r">
              <h6>CASE STUDY</h6>
              <h3>ANY ACTIVE ACCOUNT IS A THREAT</h3>
            </div>

            <p className="content">
              A client contracted with Vigilant to secure and protect their
              environments. the contract included monitoring and securing each
              of their 287 login accounts, using our Vigilant365™ service, which
              involves password security, login locations, user activity, etc.,
              with immediate, real-time visibility inside the CyberDNA® Command
              and Control Center, allowing the client to monitor and collaborate
              in everything we were seeing.
            </p>
            <div className="ctizzle">
              <Link
                to="/account-takeover/case-study"
                className="nav-section--cta-btn"
              >
                <div className="d-flex align-items-center">
                  <span
                    style={{ marginLeft: "25px" }}
                    className="nav-section--cta-title pr-5"
                  >
                    read more
                  </span>
                  <Dar className="chevy" sx={{ fontSize: 15, marginLeft: 2 }} />
                </div>
              </Link>
            </div>
          </div>
          <div className="col-sm-5">
            <StaticImage
              src="../images/security-maturity/hexAAT.png"
              quality={95}
              formats={["AUTO", "WEBP"]}
              alt="Service Placeholder"
            />
          </div>
        </div>
      </div>
    </section>
    <section className="section-resources">
      <div className="container">
        <div className="row">
          <div className="section-col-1">
            <h3>RESOURCES</h3>
          </div>
          <div className="section-col-2">
            <ul className="resources-list">
              {resourcesList.map(data => {
                return (
                  <FooterRecCard
                    altTxt={data.title}
                    title={data.title}
                    subTitle={data.subtitle}
                    recCat={data.cat}
                    LinkUrl={data.LinkUr}
                    key={uuidv4()}
                  />
                )
              })}
            </ul>
          </div>
        </div>
      </div>
    </section>
    <div className="breadcrumbs">
      <nav className="container-fluid p-sides-med">
        <ul>
          {breadData.map(breadcrumb => (
            <li key={breadcrumb.id}>
              <Link to={breadcrumb.ink}>{breadcrumb.label}</Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  </Layout>
)

export default RiskReduction
